<template>
  <div class="container">
    <template v-if="currentVisitor.platform !== 'web'"
      ><b>账号：</b> {{ sourceText }}
    </template>
    <div class="item" v-if="currentVisitor.platform === 'web'">
      <b>来源：</b> {{ sourceText }}
    </div>

    <template
      v-if="currentVisitor.platform === 'web' && currentVisitor.subscribe_ad"
    >
      <div
        class="item"
        v-if="
          currentVisitor.subscribe_ad.campaign_id &&
          currentVisitor.subscribe_ad.campaign_id !== '0'
        "
      >
        <b>广告计划：</b>
        {{
          currentVisitor.subscribe_ad.campaign_name
            ? currentVisitor.subscribe_ad.campaign_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.campaign_id }})
      </div>

      <div
        class="item"
        v-if="
          currentVisitor.subscribe_ad.adgroup_id &&
          currentVisitor.subscribe_ad.adgroup_id !== '0'
        "
      >
        <b>广告：</b>
        {{
          currentVisitor.subscribe_ad.adgroup_name
            ? currentVisitor.subscribe_ad.adgroup_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.adgroup_id }})
      </div>

      <div
        class="item"
        v-if="
          currentVisitor.subscribe_ad.creative_id &&
          currentVisitor.subscribe_ad.creative_id !== '0'
        "
      >
        <b>创意：</b>
        {{ currentVisitor.subscribe_ad.creative_name }} ({{
          currentVisitor.subscribe_ad.creative_id
        }})
      </div>

      <div
        class="item"
        v-if="
          currentVisitor.subscribe_ad.ad_account_id &&
          currentVisitor.subscribe_ad.ad_account_id !== '0'
        "
      >
        <b>广告主ID：</b>
        {{ currentVisitor.subscribe_ad.ad_account_id }}
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.fmc_account_id">
        <b>组件账户ID：</b>
        {{ currentVisitor.subscribe_ad.fmc_account_id }}
      </div>
    </template>

    <template
      v-if="
        currentVisitor.platform === 'wechat' && currentVisitor.subscribe_from
      "
    >
      <div class="item">
        <b>来源：</b> {{ currentVisitor.subscribe_from }}
        <template v-if="currentVisitor.qrcode_name">
          - {{ currentVisitor.qrcode_name }}
        </template>
      </div>
    </template>

    <template
      v-if="currentVisitor.platform === 'wechat' && currentVisitor.subscribe_ad"
    >
      <div class="item" v-if="currentVisitor.subscribe_ad.campaign_id !== '0'">
        <b>广告计划：</b>
        {{
          currentVisitor.subscribe_ad.campaign_name
            ? currentVisitor.subscribe_ad.campaign_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.campaign_id }})
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.adgroup_id">
        <b>广告：</b>
        {{
          currentVisitor.subscribe_ad.adgroup_name
            ? currentVisitor.subscribe_ad.adgroup_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.adgroup_id }})
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.ad_creative_id">
        <b>创意：</b>
        {{ currentVisitor.subscribe_ad.ad_creative_name }} ({{
          currentVisitor.subscribe_ad.ad_creative_id
        }})
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.creative_id">
        <b>创意：</b>
        {{ currentVisitor.subscribe_ad.creative_name }} ({{
          currentVisitor.subscribe_ad.creative_id
        }})
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.position">
        <b>广告位置：</b>
        {{ currentVisitor.subscribe_ad.position }}
      </div>

      <div class="item" v-if="currentVisitor.subscribe_ad.account_id">
        <b>广告主：</b>
        {{
          currentVisitor.subscribe_ad.account_name
            ? currentVisitor.subscribe_ad.account_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.account_id }} )
      </div>
    </template>

    <template
      v-if="currentVisitor.platform === 'douyin' && currentVisitor.subscribe_ad"
    >
      <div class="item">
        <b>广告计划：</b>
        {{
          currentVisitor.subscribe_ad.campaign_name
            ? currentVisitor.subscribe_ad.campaign_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.campaign_id }})
      </div>

      <div class="item">
        <b>广告ID：</b>

        ({{ currentVisitor.subscribe_ad.adgroup_id }})
      </div>

      <div class="item">
        <b>创意ID：</b>
        {{ currentVisitor.subscribe_ad.creative_id }}
      </div>

      <div class="item">
        <b>广告主ID：</b>
        {{ currentVisitor.subscribe_ad.account_id }}
      </div>
    </template>

    <template
      v-if="
        currentVisitor.platform === 'redbook' && currentVisitor.subscribe_ad
      "
    >
      <template v-if="currentVisitor.subscribe_ad.campaign_id">
        <div class="item">
          <b>广告计划：</b>
          {{
            currentVisitor.subscribe_ad.campaign_name
              ? currentVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentVisitor.subscribe_ad.campaign_id }})
        </div>

        <div class="item">
          <b>创意：</b>
          {{
            currentVisitor.subscribe_ad.creative_name
              ? currentVisitor.subscribe_ad.creative_name
              : "ID"
          }}
          ({{ currentVisitor.subscribe_ad.creative_id }})
        </div>

        <div class="item">
          <b>广告主：</b>
          {{
            currentVisitor.subscribe_ad.account_name
              ? currentVisitor.subscribe_ad.account_name
              : "ID"
          }}
          ({{ currentVisitor.subscribe_ad.account_id }})
        </div>
      </template>

      <div class="item" v-if="currentVisitor.subscribe_ad.note_info">
        <b>笔记：</b>
        {{ currentVisitor.subscribe_ad.note_info.title }}
      </div>
    </template>

    <template
      v-if="
        currentVisitor.platform === 'kuaishou' && currentVisitor.subscribe_ad
      "
    >
      <div class="item">
        <b>广告计划：</b>
        {{
          currentVisitor.subscribe_ad.campaign_name
            ? currentVisitor.subscribe_ad.campaign_name
            : "ID"
        }}
        ({{ currentVisitor.subscribe_ad.campaign_id }})
      </div>

      <div class="item">
        <b>广告组ID：</b>
        {{ currentVisitor.subscribe_ad.adgroup_id }}
      </div>

      <div class="item">
        <b>创意ID：</b>
        {{ currentVisitor.subscribe_ad.creative_id }}
      </div>
    </template>

    <template
      v-if="
        currentVisitor.platform === 'kuaishou' &&
        currentVisitor.subscribe_scene &&
        !currentVisitor.subscribe_ad
      "
    >
      <div class="item">
        <b>来源：</b>

        <template v-if="currentVisitor.subscribe_scene === 'unknown'"
          >未知</template
        >
        <template v-if="currentVisitor.subscribe_scene === 'normal'"
          >自然流量</template
        >
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { DefaultSourceMap } from "@/data/source";

export default defineComponent({
  name: "SourceDialog",

  data() {
    return {};
  },

  beforeMount() {
    console.log(this.currentVisitor);
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
    }),
    ...mapState({
      wechatApps: (state) => state.common.wechatAppsObj,
      weiboApps: (state) => state.common.weiboAppsObj,
      douyinApps: (state) => state.common.douyinAppsObj,
      kuaishouApps: (state) => state.common.kuaishouAppsObj,
      redbookApps: (state) => state.common.redbookAppsObj,
    }),
    platform: function () {
      return this.currentVisitor?.platform || "";
    },
    sourceText: function () {
      if (!this.currentVisitor) return "";
      switch (this.currentVisitor.platform) {
        case "web":
          return this.getWebSourceText(this.currentVisitor.referrer);
        case "wechat":
          return this.getWechatSourceText();
        case "weibo":
          return this.weiboApps[this.currentVisitor.appid]?.name;
        case "douyin":
          return this.douyinApps[this.currentVisitor.appid]?.name;
        case "kuaishou":
          return this.kuaishouApps[this.currentVisitor.appid]?.name;
        case "redbook":
          return this.redbookApps[this.currentVisitor.appid]?.name;
        default:
          return "";
      }
    },
  },

  methods: {
    getWebSourceText(referrer) {
      const source = referrer.source;
      let displayText = "";

      if (source === "search") {
        displayText = DefaultSourceMap[referrer.engine];
      } else if (source === "custom") {
        displayText = `自定义(${referrer.custom_source})`;
      } else {
        displayText = DefaultSourceMap[source];
      }

      if (referrer.keyword) {
        displayText += `(${referrer.keyword})`;
      }

      return displayText;
    },

    getWechatSourceText() {
      const wechatApp = this.wechatApps[this.currentVisitor.appid];

      let displayText = "";

      if (wechatApp?.corp_name) {
        displayText = `微信客服（${wechatApp.name} @${wechatApp.corp_name}）`;
      } else {
        let prefix = "";
        switch (wechatApp?.type) {
          case 0:
            prefix = "微信公众号";
            break;
          case 2:
            prefix = "微信小程序";
            break;
          case 3:
            prefix = "微信客服";
            break;
          case 4:
            prefix = "微信小店";
            break;
          default:
            prefix = "微信公众号";
            break;
        }
        displayText = `${prefix}（${wechatApp?.name}）`;
      }

      return displayText;
    },
  },
});
</script>

<style scoped>
/* Component styles here */
.container {
  padding: 20px;
  font-size: 15px;
}
</style>
