<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>

  <sound-manager />

  <van-dialog v-model:show="authDialogVisible">
    <div style="padding: 24px 26px">
      {{ authDialogMessage }}
    </div>
  </van-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { addTokenInterceptor } from "@/utils/http";
import { getCompanyInfo, getWorkerList } from "@/api/common";
import { format, parseISO } from "date-fns";
import { Dialog } from "vant";
import SoundManager from "@/components/SoundManager.vue";
import CommetFactory from "@/api/CommetFactory";
import { ERROR_TYPES } from "@/enums/error-types";
import websocketFactory from "@/api/websocketFactory";
import { filter, map, catchError, tap } from "rxjs/operators";
import "vant/es/dialog/style";

export default defineComponent({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    SoundManager,
  },

  created() {
    addTokenInterceptor();

    const Commet = CommetFactory(this.$store);
    Commet.connect();

    const ws = websocketFactory();
    ws.messages$
      .pipe(
        filter((rows) => rows.data),
        map((rows) => {
          return {
            ...rows.data,
            microtime: rows.microtime,
          };
        }),
        catchError((error) => {
          throw error;
        }),
        tap({
          error: (error) => console.log("[Live component] Error:", error),
          complete: () => console.log("[Live component] Connection Closed"),
        })
      )
      .subscribe((res) => {
        this.$store.dispatch("UPDATE_UNREAD_COUNT", res?.body?.unread || 0);
      });

    ws.connect(localStorage.getItem("token"));

    this.$store.dispatch("GET_APPS_LIST");
    this.$store.dispatch("UPDATE_BADGE");

    this.getWorkerInfo();
    this.getBillingInfo();
  },

  data() {
    return {
      expireDate: "",
      authDialogVisible: false,
      authDialogMessage: "",

      expireDialogVisible: false,
      expireDialogMessage: "",
    };
  },

  computed: mapState({
    failure: (state) => state.auth.failure,
  }),

  watch: {
    authDialogVisible(val) {
      if (!val) {
        this.$store.commit("AUTHENTICATE_FAILURE", "");
      }
    },

    failure() {
      console.log("failure", this.failure);
      this.$store.commit("UPDATE_DUTY_STATUS", false);

      if (this.failure === ERROR_TYPES.INVALID_TOKEN) {
        this.authDialogMessage = "您的登录链接已失效，请返回重试";
        this.authDialogVisible = true;
      }

      if (this.failure === ERROR_TYPES.USE_MOBILE_PERMISSION_FAIL) {
        this.authDialogMessage =
          "您已被禁止使用手机值班功能，请联系管理员为您开启";
        this.authDialogVisible = true;
      }

      if (this.failure === ERROR_TYPES.WORKER_NOT_ONLINE) {
        this.authDialogMessage = "您已将值班状态设置成离线，可在设置页面修改。";
        this.authDialogVisible = true;
      }
    },
  },

  methods: {
    closeAuthDialog() {
      console.log("closedialog");
      this.$store.commit("AUTHENTICATE_FAILURE", "");
      return true;
    },

    getWorkerInfo() {
      getWorkerList().subscribe((workerList) => {
        const workerName = localStorage.getItem("workername");
        const worker = workerList.find((w) => {
          return w.name === workerName;
        });
        if (worker) {
          localStorage.setItem("worker_data", JSON.stringify(worker));
        }
      });
    },

    getBillingInfo() {
      getCompanyInfo().subscribe((res) => {
        localStorage.setItem("company_code", res.code);
        localStorage.setItem("advisor", JSON.stringify(res.advisor));

        let version = res.payment.verson;
        let checkArr =
          version === "ai2024"
            ? ["service_time_end", "wxapp_time_end", "wbapp_time_end"]
            : ["service_time_end"];

        checkArr.forEach((prop) => {
          if (res.payment[prop]) {
            this.checkProp(prop, res);
          }
        });
      });
    },

    checkProp(prop, res) {
      let servceTimeEndText =
        res.payment.version === "ai2024" ? "购买的【AI智能客服】" : "的云雀席位";

      const name = {
        service_time_end: servceTimeEndText,
        wxapp_time_end: "增加的微信公众号/小程序/微信客服/微信小店配额",
        wbapp_time_end: "增加的抖音/快手/小红书/微博/头条咨询页配额",
      };

      const now = new Date();
      const endTime = parseISO(res.payment[prop]);
      this.expireDate = format(endTime, "yyyy-MM-dd");

      const dayDifference = Math.ceil((endTime - now) / 86400000);
      if (dayDifference <= 0) {
        Dialog.alert({
          message: `尊敬的用户，\n您${name[prop]}已于${this.expireDate}到期，请使用电脑登陆管理员账号充值续费，以免影响您的使用`,
        });
      } else if (dayDifference <= 3) {
        Dialog.alert({
          message: `尊敬的用户，\n您${name[prop]}即将于${this.expireDate}到期，请使用电脑登陆管理员账号充值续费，以免影响您的使用`,
        });
      }
    },
  },
});
</script>
